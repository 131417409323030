/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { Global } from '@emotion/react'
import PropTypes from 'prop-types'

import { Footer, Header } from './'
import globalStyles from '../globalStyles'
import '../fonts/fonts.css'

const Layout = ({ aboveImage, children }) => (
  <React.Fragment>
    <Global styles={globalStyles} />
    <Header aboveImage={aboveImage} />
    <main>{children}</main>
    <Footer />
  </React.Fragment>
)

Layout.propTypes = {
  aboveImage: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Layout
