/** @jsx jsx */
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { jsx, Flex, Themed } from 'theme-ui'

import { Image, Section } from '../'

const TourCard = (props) => (
  <Themed.a
    as={Link}
    sx={{
      background: 'white',
      borderRadius: 'md',
      boxShadow: 'sm',
      display: 'block',
      flex: (t) => [
        `0 1 100%`,
        `0 1 calc(50% - ${t.space[3] * 2}px)`,
        `0 1 calc(${(1 / 3) * 100}% - ${t.space[3] * 2}px)`,
      ],
      fontStyle: 'normal',
      my: 3,
      mx: 3,
      overflow: 'hidden',
      transition: 'all .5s',
      ':hover': {
        boxShadow: 'md',
      },
    }}
    to={props.url}
  >
    <Image
      fullWidth
      sx={{ height: 300 }}
      image={props.image.gatsbyImageData}
      alt={props.image.alt}
    />
    <div sx={{ color: 'text', p: 4 }}>
      <Themed.h5
        as="h3"
        sx={{
          my: 0,
          wordBreak: 'break-word' /** TODO: Fix word-breaks with &shy; */,
        }}
      >
        {props.title.text}
      </Themed.h5>
      <Themed.p sx={{ mt: 2 }}>{props.subtitle.text}</Themed.p>
    </div>
  </Themed.a>
)

TourCard.propTypes = {
  title: PropTypes.object,
  subtitle: PropTypes.object,
  image: PropTypes.object,
  url: PropTypes.string,
}

export const TourCards = (props) => (
  <Section>
    <Themed.h2>
      <span
        sx={{
          color: 'accent',
          display: 'block',
          fontSize: 2,
          mb: 2,
          textTransform: 'uppercase',
        }}
      >
        Wien von A–Z
      </span>
      Themen & Touren
    </Themed.h2>
    <Flex
      sx={{ flexWrap: 'wrap', mt: 4, mx: -3, justifyContent: 'space-between' }}
    >
      {props.tours.map((tour, index) => (
        <TourCard {...tour.data} url={tour.url} key={index} />
      ))}
    </Flex>
    <Flex>
      {props.showMore ? (
        <Link
          to="/tours/"
          sx={{
            variant: 'buttons.primary',
          }}
        >
          Alle Touren anzeigen
        </Link>
      ) : null}
    </Flex>
  </Section>
)

TourCards.propTypes = {
  showMore: PropTypes.bool,
  tours: PropTypes.array.isRequired,
}

export default TourCard
