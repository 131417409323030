/** @jsx jsx */
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'

import htmlSerializer from '../../../utils/htmlSerializer'
import { Section } from '../../'

const TextSlice = ({ field }) => (
  <Section
    sx={{
      h2: { fontSize: [3, 4] },
      h3: { fontSize: [2, 3] },
      h4: { fontSize: [1, 2] },
    }}
  >
    <div
      sx={{
        maxWidth: '70ch',
        '> :first-child': { mt: 0 },
      }}
    >
      <RichText render={field} htmlSerializer={htmlSerializer} />
    </div>
  </Section>
)

TextSlice.propTypes = {
  field: PropTypes.array.isRequired,
}

export default TextSlice
