/** @jsx jsx */
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'

import { Section } from '.'
import {
  Image,
  Newsletter,
  Quote,
  QuoteAndText,
  Text,
  TextAndImage,
  TourCards,
} from './Slices/'

const Slices = ({ slices }) => {
  if (!slices) return null

  return slices.map((s, index) => {
    switch (s.slice_type) {
      case 'text':
        return <Text key={'slice' + index} field={s.primary.text.raw} />
      case 'image': {
        return (
          <Image
            key={'slice' + index}
            alt={s.primary.image.alt}
            image={s.primary.image.gatsbyImageData}
          />
        )
      }
      case 'text_and_image': {
        return (
          <TextAndImage
            key={'slice' + index}
            image={s.primary.image.gatsbyImageData}
            imageFirst={s.primary.image_first}
            text={s.primary.text.raw}
          />
        )
      }
      case 'quote_and_text': {
        return (
          <QuoteAndText
            key={'slice' + index}
            description={s.primary.quote_description}
            image={s.primary.image.gatsbyImageData}
            quote={s.primary.quote.text}
            text={s.primary.text.raw}
            link={s.primary.button_link.url}
            buttonText={s.primary.button_text}
          />
        )
      }
      case 'tour_cards':
        return <TourCards key={'slice' + index} showMore />
      case 'quote':
        return (
          <Quote
            key={'slice' + index}
            quote={s.primary.quote}
            description={s.primary.description}
          />
        )
      case 'newsletter':
        return <Newsletter key={'slice' + index} />
      default:
        return <Section key={'slice' + index}>{s.slice_type}</Section>
    }
  })
}

Slices.propTypes = {
  slices: PropTypes.array,
}

export default Slices
