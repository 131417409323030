/** @jsx jsx */
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { Section } from '../..'
import { Flex, jsx } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'

import htmlSerializer from '../../../utils/htmlSerializer'

export const PureNewsletterSlice = ({ disclaimer, id, text, u, url }) => (
  <Section>
    <Flex
      sx={{
        backgroundColor: 'secondary',
        borderRadius: 'md',
        boxShadow: 'sm',
        color: 'text',
        display: ['block', null, 'flex'],
      }}
    >
      <div sx={{ flex: 2, p: [4, null, 5] }}>
        <div sx={{ maxWidth: '40ch', '> h2, > h3': { mt: 0 } }}>
          <RichText render={text} htmlSerializer={htmlSerializer} />
        </div>
      </div>
      <Flex
        sx={{
          justifyContent: 'center',
          flex: 3,
          flexDirection: 'column',
          p: [4, null, 5],
          pt: [0, null, 5],
        }}
      >
        <form action={url} rel="noopener noreferrer" target="_blank">
          <input type="hidden" name="u" value={u} />
          <input type="hidden" name="id" value={id} />
          <label htmlFor="email" sx={{ variant: 'visuallyHidden' }}>
            Email-Adresse
          </label>
          <input
            sx={{
              bg: 'white',
              border: 'none',
              borderColor: 'transparent',
              borderRadius: 'sm',
              borderStyle: 'solid',
              borderWidth: 3,
              color: 'text',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              mr: 3,
              px: 3,
              py: 1,
              '::placeholder': {
                color: 'muted',
              },
              width: '100%',
              '&:focus': {
                borderColor: 'background',
                outline: 'none',
              },
            }}
            id="email"
            name="MERGE0"
            type="email"
            placeholder="Email-Adresse"
          ></input>
          <button
            sx={{
              variant: 'buttons.outline.text',
              display: 'block',
              mt: 4,
              width: '100%',
            }}
            type="submit"
          >
            Newsletter bestellen
          </button>
          <div
            sx={{
              display: 'block',
              fontSize: 1,
              mt: 4,
              a: { color: 'text' },
            }}
          >
            <RichText render={disclaimer} htmlSerializer={htmlSerializer} />
          </div>
        </form>
      </Flex>
    </Flex>
  </Section>
)

PureNewsletterSlice.propTypes = {
  disclaimer: PropTypes.array,
  id: PropTypes.string.isRequired,
  text: PropTypes.array,
  u: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

const NewsletterSlice = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            mailchimp {
              url
              u
              id
            }
          }
        }
        prismicSettings {
          data {
            newsletter_text {
              raw
            }
            newsletter_disclaimer {
              raw
            }
          }
        }
      }
    `
  )

  if (!data.prismicSettings) return null

  const { newsletter_text, newsletter_disclaimer } = data.prismicSettings.data

  return (
    <PureNewsletterSlice
      {...data.site.siteMetadata.mailchimp}
      text={newsletter_text.raw}
      disclaimer={newsletter_disclaimer.raw}
    />
  )
}

export default NewsletterSlice
