module.exports = function (doc) {
  if (!doc) return '/'

  if (doc.type === 'page') {
    return '/' + doc.uid
  }

  if (doc.type === 'tour') {
    return '/tour/' + doc.uid
  }

  if (doc.type === 'tour_category') {
    return '/tours'
  }

  // Homepage route fallback
  return '/'
}
