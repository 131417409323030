/** @jsx jsx */
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { jsx } from 'theme-ui'

const Image = (props) => {
  const { alt, fullWidth, objectFit, objectPosition, ...rest } = props

  return (
    <GatsbyImage
      {...rest}
      alt={alt ?? ''}
      sx={{
        borderRadius: fullWidth ? 'none' : 'sm',
        width: '100%',
      }}
      imgStyle={{ objectPosition, objectFit }}
    />
  )
}

Image.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.object,
  fullWidth: PropTypes.bool,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
}

Image.defaultProps = {
  objectFit: 'cover',
  objectPosition: 'center center',
}

export default Image
