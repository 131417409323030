import { css } from 'theme-ui'

export default css({
  '*': {
    boxSizing: 'border-box',
  },
  body: {
    margin: 0,
    padding: 0,
    textRendering: `optimizeLegibility`,
    WebkitFontSmoothing: `antialiased`,
    MozOsxFontSmoothing: `grayscale`,
  },
  img: {
    filter: 'grayscale(0.25) contrast(0.5) brightness(1.25)',
    // filter: 'contrast(0.75) brightness(1.25)'
  },
  'button:hover': {
    cursor: 'pointer',
  },
})
