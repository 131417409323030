/** @jsx jsx */
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Container, jsx, MenuButton, Themed } from 'theme-ui'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { links as footerLinks } from '../Footer/Footer'

const links = [
  {
    title: 'Über mich',
    slug: '/ueber-mich',
  },
  {
    title: 'Touren',
    slug: '/tours',
  },
  {
    title: 'Kontakt',
    slug: '/kontakt',
  },
]

export const PureHeader = ({ data, aboveImage }) => {
  const [open, setOpen] = useState(false)

  const toggleNav = () => {
    setOpen(!open)
  }

  return (
    <header
      sx={{
        position: aboveImage ? 'absolute' : 'relative',
        width: '100%',
        zIndex: 20,
      }}
    >
      <Container
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          padding: 4,
        }}
      >
        <Themed.a
          as={Link}
          sx={{
            color: aboveImage ? 'white' : 'text',
            display: 'block',
            fontFamily: 'heading',
            fontSize: [3, 4],
            fontWeight: 'bold',
            margin: 0,
            textAlign: 'left',
          }}
          to="/"
        >
          {data.site.siteMetadata.title}
        </Themed.a>
        <nav sx={{ textAlign: [!open && 'center', 'right'] }}>
          <MenuButton
            aria-label="Toggle Menu"
            onClick={() => toggleNav()}
            sx={{
              bg: 'white',
              display: ['block', 'none'],
              ml: 'auto',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          />
          <ul
            sx={{
              bg: [open && 'white', 'transparent'],
              borderRadius: 'sm',
              boxShadow: [open && 'sm', 'none'],
              display: [open ? 'block' : 'none', 'block'],
              left: 0,
              right: 0,
              listStyle: 'none',
              m: [open && 4, 0],
              mt: [open && 2, 0],
              padding: [open ? 4 : 0, 0],
              position: ['absolute', 'static'],
            }}
          >
            {links.map((item, index) => (
              <li
                sx={{
                  display: [!open && 'inline', 'inline'],
                  ml: [!open && index > 0 && 4, index > 0 && 4],
                  mt: open && index > 0 && 3,
                }}
                key={'link-' + index}
              >
                <Themed.a
                  as={Link}
                  to={item.slug}
                  sx={{
                    color: [
                      aboveImage && !open ? 'white' : 'text',
                      aboveImage ? 'white' : 'text',
                    ],
                    fontWeight: 'bold',
                  }}
                >
                  {item.title}
                </Themed.a>
              </li>
            ))}
            {footerLinks.map((item, index) => (
              <li
                key={`footer-link-${index}`}
                sx={{
                  borderWidth: 1,
                  borderTopStyle: index === 0 && 'solid',
                  borderColor: 'background',
                  display: [null, 'none'],
                  mt: index > 0 ? 2 : 3,
                  pt: index == 0 && 3,
                }}
              >
                <Themed.a as={Link} to={item.slug} sx={{ fontSize: 1 }}>
                  {item.title}
                </Themed.a>
              </li>
            ))}
          </ul>
        </nav>
      </Container>
    </header>
  )
}

PureHeader.propTypes = {
  aboveImage: PropTypes.bool,
}

PureHeader.defaultProps = {
  aboveImage: false,
}

const Header = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return <PureHeader {...props} data={data} />
}

export default Header
