/** @jsx jsx */
import { Flex, jsx, Themed } from 'theme-ui'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { graphql, useStaticQuery } from 'gatsby'

import { EventCard, Section } from '../../'
import htmlSerializer from '../../../utils/htmlSerializer'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

export const PureEventCards = ({ events, title, subtitle, text }) =>
  events.length ? (
    <Section sx={{ bg: 'white' }}>
      <div sx={{ maxWidth: '50ch' }}>
        <Themed.h2 sx={{ mt: 0 }}>
          <span
            sx={{
              color: 'accent',
              display: 'block',
              fontSize: 2,
              mb: 2,
              textTransform: 'uppercase',
            }}
          >
            {title}
          </span>
          {subtitle}
        </Themed.h2>
        <RichText render={text} htmlSerializer={htmlSerializer} />
      </div>
      <Flex
        sx={{
          mb: -4,
          mx: (t) =>
            `calc((100vw - min(${t.sizes.container}px, 100vw) + ${t.space[5]}px) * -.5)`,
          py: 4,
          px: (t) => `calc((100vw - ${t.sizes.container}px) * .5)`,
          overflowX: 'auto',
          scrollbarWidth: 'none',
          zIndex: 10,
          '&::-webkit-scrollbar, -ms-overflow-style': {
            display: 'none',
          },
          '&:after, &:before': {
            content: '""',
            flex: (t) => `0 0 ${t.space[3]}px`,
          },
        }}
      >
        {events.map((event, index) => (
          <EventCard {...event.data} key={index} />
        ))}
      </Flex>
    </Section>
  ) : null

PureEventCards.propTypes = {
  events: PropTypes.array,
  subtitle: PropTypes.string,
  text: PropTypes.array,
  title: PropTypes.string,
}

PureEventCards.defaultProps = {
  events: [],
}

const EventCards = ({ timestamp }) => {
  const staticData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            mailchimp {
              url
              u
              id
            }
          }
        }
        prismicSettings {
          data {
            events_title {
              text
            }
            events_subtitle {
              text
            }
            events_text {
              raw
            }
          }
        }
        allPrismicEvent {
          nodes {
            _previewable
            data {
              end_time
              meeting_point
              price
              start_time
              status
              tour {
                document {
                  ... on PrismicTour {
                    _previewable
                    id
                    uid
                    type
                    url
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const { data } = useMergePrismicPreviewData(staticData)

  const events = data.allPrismicEvent.nodes
    .filter((e) => new Date(e.data.start_time) > new Date(timestamp))
    .sort((a, b) => new Date(a.data.start_time) - new Date(b.data.start_time))

  if (!data.prismicSettings) return null

  const { events_title, events_subtitle, events_text } =
    data.prismicSettings.data

  return (
    <PureEventCards
      events={events}
      subtitle={events_subtitle.text}
      text={events_text.raw}
      title={events_title.text}
    />
  )
}

EventCards.propTypes = {
  timestamp: PropTypes.string.isRequired,
}

export default EventCards
