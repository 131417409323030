/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'

import htmlSerializer from '../../../utils/htmlSerializer'
import { Image, Section } from '../..'

const TextAndImageSlice = ({ image, imageFirst, text }) => (
  <Section>
    <div sx={{ alignItems: 'center', display: ['block', 'flex'], mx: -3 }}>
      <Box
        sx={{
          flex: 1,
          order: [null, !imageFirst ? 2 : null],
          px: 3,
          pb: [4, 0],
        }}
      >
        <Image image={image} />
      </Box>
      <Box sx={{ flex: 1, px: 3, '> h2, > h3': { mt: 0 } }}>
        <RichText render={text} htmlSerializer={htmlSerializer} />
      </Box>
    </div>
  </Section>
)

TextAndImageSlice.propTypes = {
  image: PropTypes.object.isRequired,
  imageFirst: PropTypes.bool,
  text: PropTypes.array.isRequired,
}

export default TextAndImageSlice
