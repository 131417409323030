/** @jsx jsx */
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { jsx, Flex, Themed } from 'theme-ui'

import { Section, TourCard } from '../../'

export const PureTourCards = ({ tours, showMore }) => (
  <Section>
    <Themed.h2 sx={{ mt: 0 }}>
      <span
        sx={{
          color: 'accent',
          display: 'block',
          fontSize: 2,
          mb: 2,
          textTransform: 'uppercase',
        }}
      >
        Wien von A–Z
      </span>
      Themen & Touren
    </Themed.h2>
    <Flex
      sx={{
        flexWrap: 'wrap',
        mt: 4,
        mx: -3,
        justifyContent: 'space-between',
      }}
    >
      {tours.map((tour, index) => (
        <TourCard {...tour.data} url={tour.url} key={index} />
      ))}
    </Flex>
    <Flex>
      {showMore ? (
        <Link
          to="/tours/"
          sx={{
            variant: 'buttons.primary',
          }}
        >
          Alle Touren anzeigen
        </Link>
      ) : null}
    </Flex>
  </Section>
)

PureTourCards.propTypes = {
  showMore: PropTypes.bool,
  tours: PropTypes.array.isRequired,
}

const TourCards = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        allPrismicTour(
          sort: { fields: last_publication_date, order: DESC }
          limit: 3
        ) {
          nodes {
            _previewable
            url
            id
            uid
            type
            data {
              title {
                text
              }
              subtitle {
                text
              }
              image {
                url
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  )

  return <PureTourCards {...props} tours={data.allPrismicTour.nodes} />
}

export default TourCards
