/** @jsx jsx */
import { jsx, Themed } from 'theme-ui'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Date } from 'prismic-reactjs'

const EventCard = (props) => {
  const start_date = new Date(props.start_time)

  const date = Intl.DateTimeFormat('de-AT', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  }).format(start_date)

  const getStatus = (props) => {
    const values = {
      color: 'success',
      status: 'Plätze verfügbar',
    }

    switch (props.status) {
      case 'booked-out':
        values.color = 'error'
        values.status = 'Leider Ausgebucht'
        return values
      case 'almost-booked-out':
        values.color = 'warning'
        values.status = 'Wenig Plätze verfügbar'
        return values
      default:
        return values
    }
  }

  const { status, color } = getStatus(props)

  return (
    <div
      {...props}
      sx={{
        bg: 'white',
        borderRadius: 'md',
        boxShadow: 'sm',
        color: 'text',
        flex: '0 0 auto',
        minWidth: 320,
        maxWidth: 450,
        mx: 3,
        p: 4,
        transition: 'all .5s',
        ':hover': {
          boxShadow: 'md',
        },
      }}
    >
      <div sx={{ fontWeight: 600 }}>{date}</div>
      <Themed.a as={Link} to={props.tour.document?.url ?? ''}>
        <Themed.h5 as="h3" sx={{ color: 'text', mt: 0 }}>
          {props.tour.document?.data.title.text}
        </Themed.h5>
      </Themed.a>
      <Themed.p sx={{ mt: 1 }}>
        {props.meeting_point}, {props.price}€{' '}
      </Themed.p>
      <div
        sx={{
          bg: color,
          borderRadius: 'sm',
          color: 'muted',
          display: 'inline-block',
          fontSize: 0,
          mt: 2,
          px: '.5em',
          py: '.2em',
        }}
      >
        {status}
      </div>
    </div>
  )
}

EventCard.propTypes = {
  tour: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  meeting_point: PropTypes.string.isRequired,
  start_time: PropTypes.string.isRequired,
}

export default EventCard
