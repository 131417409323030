/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, Themed } from 'theme-ui'

import { Section } from '../..'

const QuoteSlice = ({ quote, description }) => (
  <Section sx={{ bg: 'white', my: 4 }}>
    <div sx={{ maxWidth: '70ch' }}>
      <blockquote sx={{ m: 0 }}>
        <Themed.p
          sx={{
            fontFamily: 'heading',
            fontSize: 4,
            fontWeight: 'light',
            mt: 0,
          }}
        >
          „{quote}“
        </Themed.p>
        <footer>– {description}</footer>
      </blockquote>
    </div>
  </Section>
)

QuoteSlice.propTypes = {
  quote: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default QuoteSlice
