/** @jsx jsx */
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { Image, Section } from '../..'
import { Flex, jsx, Themed } from 'theme-ui'
import { Link } from 'gatsby'

import htmlSerializer from '../../../utils/htmlSerializer'

const QuoteAndText = ({
  quote,
  description,
  image,
  text,
  link,
  buttonText,
}) => (
  <Section fluid flex sx={{ mb: 4 }}>
    <Flex
      sx={{
        alignItems: 'center',
        bg: 'accent',
        color: 'white',
        flex: 2,
        minHeight: 300,
        px: [4, null, 5],
        position: 'relative',
      }}
    >
      <blockquote
        sx={{
          m: 0,
          ml: (t) =>
            `calc(max((100vw - ${t.sizes.container}px - ${t.space[5]}px), 0px) * .5)`,
          position: 'relative',
          zIndex: 10,
        }}
      >
        <Themed.p sx={{ fontFamily: 'heading', fontSize: 4, mt: 0 }}>
          „{quote}“
        </Themed.p>
        <footer sx={{ mt: 3 }}>– {description}</footer>
      </blockquote>
      <Image
        alt=""
        image={image}
        sx={{
          position: 'absolute !important',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 0.2,
          mixBlendMode: 'darken',
        }}
      />
    </Flex>
    <div sx={{ bg: 'white', flex: 3 }}>
      <div
        sx={{
          mr: (t) => [
            null,
            `calc(max((100vw - ${t.sizes.container}px - ${t.space[5]}px), 0px) * .5)`,
          ],
          p: [4, null, 5],
          '> h2, > h3': { mt: 0 },
        }}
      >
        <RichText render={text} htmlSerializer={htmlSerializer} />
        {link && (
          <Link to={link} sx={{ variant: 'buttons.outline.accent' }}>
            {buttonText}
          </Link>
        )}
      </div>
    </div>
  </Section>
)

QuoteAndText.propTypes = {
  quote: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  text: PropTypes.array,
  link: PropTypes.string,
  buttonText: PropTypes.string,
}

export default QuoteAndText
