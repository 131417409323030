/** @jsx jsx */
import { Link } from 'gatsby'
import { Container, jsx, Themed } from 'theme-ui'

import AustriaGuidesLogo from '../../../src/images/austria-guides-logo.svg'

export const links = [
  {
    title: 'Preise & AGBs',
    slug: '/preise-agbs',
  },
  {
    title: 'Impressum',
    slug: '/impressum',
  },
  {
    title: 'Datenschutz',
    slug: '/datenschutz',
  },
]

const Footer = () => {
  return (
    <footer sx={{ mt: 4 }}>
      <Container
        sx={{ padding: 4, display: ['block', 'flex'], alignItems: 'center' }}
      >
        <Themed.a
          href="https://www.wko.at/site/Austria_Guides/start.html"
          target="_blank"
          title="Austria Guides – Österreich persönlich
          Fremdenführerinnen und Fremdenführer"
          rel="noopener noreferrer"
        >
          <img
            src={AustriaGuidesLogo}
            alt="Austria Guides Logo"
            sx={{ width: 150 }}
          />
        </Themed.a>

        <nav sx={{ flex: 1, textAlign: ['left', 'right'] }}>
          <ul sx={{ listStyle: 'none', mb: 0, mt: [4, 0], padding: 0 }}>
            {links.map((item, index) => (
              <li
                sx={{ display: [null, 'inline'], pl: [0, index > 0 && 4] }}
                key={'link-' + index}
              >
                <Themed.a as={Link} to={item.slug} sx={{ color: 'text' }}>
                  {item.title}
                </Themed.a>
              </li>
            ))}
          </ul>
        </nav>
      </Container>
    </footer>
  )
}

export default Footer
