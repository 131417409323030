/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, Container } from 'theme-ui'

const Section = ({ children, className, flex, fluid }) => (
  <section className={className}>
    <Container
      sx={{
        display: ['block', null, flex ? 'flex' : null],
        maxWidth: fluid ? 'none' : null,
        px: fluid ? 0 : 4,
        py: fluid ? 0 : 4,
      }}
    >
      {children}
    </Container>
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  flex: PropTypes.bool,
  fluid: PropTypes.bool,
}

Section.defaultProps = {
  fluid: false,
}

export default Section
