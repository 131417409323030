/** @jsx jsx */
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { Image } from '../..'
import { Section } from '../..'

const ImageSlice = (props) => {
  return (
    <Section fluid={props.fluid}>
      <Image {...props} fullWidth={props.fluid} />
    </Section>
  )
}

ImageSlice.propTypes = {
  alt: PropTypes.string,
  fluid: PropTypes.bool,
  image: PropTypes.object,
  height: PropTypes.string,
  objectPosition: PropTypes.string,
}

export default ImageSlice
